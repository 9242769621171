import FooterText from '../webshop/FooterText'
import environment from '@gruene-brise/data-access/lib/config/environment'
import { useTranslation } from 'react-i18next'
import Wallpaper from './Wallpaper'
import WebshopNavigation from '../navigation/WebshopNavigation'
import classNames from 'classnames'

export function UserSignUp({
  hideHeader,
  hideDoctor,
  className
}: {
  onLogin?(): void
  hideHeader?: boolean
  hideDoctor?: boolean
  className?: string
}) {
  const { t, i18n } = useTranslation()

  const loginUrl = environment.algeaLoginUrl + 'register/embed/'

  const form = (
    <iframe
      className="w-full md:h-[800px] h-[1200px]"
      src={`${loginUrl}${i18n?.language}`}
    ></iframe>
  )

  const title = (
    <div
      className={classNames(
        className,
        `font-semibold font-gellix text-[28px] md:text-[32px] lg:text-[42px] w-full`
      )}
    >
      {t('Create Account')}
    </div>
  )

  return hideHeader ? (
    <>
      {title}
      {form}
    </>
  ) : (
    <WebshopNavigation showTopMenu={false} isDark greyBg={false}>
      <div className="w-screen h-screen  overflow-hidden">
        <div className="w-full h-full flex flex-row">
          <Wallpaper />
          <div className="z-50 items-center justify-center flex flex-col overflow-auto scrollbar-hide overflow-hidden w-full xl:w-1/2 lg:w-1/2">
            <div className="w-full h-full flex flex-col items-center">
              <>
                <div className="p-4 w-full">{title}</div>

                <div className={`p-4 ${className} w-full min-h-full`}>
                  {form}
                  {hideDoctor ? (
                    <></>
                  ) : (
                    <div className="w-full mt-[150px]">
                      <FooterText />{' '}
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </WebshopNavigation>
  )
}

export default UserSignUp
